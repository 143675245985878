import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Poster from './pages/Poster';

function App() {


  return (
        <Router>
          <Routes>
            <Route path="/" element={<Poster />} />
            {/* <Route path="*" element={<PageNotFound />} /> */}
          </Routes>
        </Router>
  );
}

export default App;
